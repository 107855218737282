/* PORTLET-GRUNDBAU */

.portletWrapper {
  /* position: relative; */
}

.card.portlet {
  border: none;
}

.portlet {
  .card-header {
    margin: 0 .7rem 0 0;
	font-size: .8rem;
	padding: 0;

	button[type="submit"] {
		padding: 0;
	}
  }

  .card-body {
    > ul {
      li:hover {
        background-color: transparent;
        text-decoration: none;
		color: #086ca3;
      }
	  li a {
	    padding: .5em .2em .5em 0;
		/*color: #000;*/
	  }
	  li time {
	    display: none;
	  }
	  li time.portletItemDetails {
	    display: block;
	  }
    }
  }
  section.card-body > * {
	padding: 0 !important;
  }
  .portletFooter {
    padding: .2rem .7rem;
	background: none;
	box-shadow: none;
  }
}
.portlet .card-body > ul > li a, .portlet .card-body > li {
	padding: .3rem 0;
}
.portlet .card-body > ul .portletItemDetails {
	padding: 0;
	margin-top: 0;
	text-align: left;
}
.portletCalendar .card-body {
	padding: 0 !important;
}

.portlet-static img {
  max-width: 100%;
}
.portletStaticText {
  .card-body {
    ul, ol {
      line-height: 1.3em;
      padding: 0;
      list-style-position: outside;
      list-style-image: none !important;
      list-style-type: none !important;
	}
	ol {
	  margin: 0 0 0.5em 1.5em;
	}
	ul {
	  margin: 0 0 0.5em 0.9em;
	  list-style-image: url('++resource++humboldt.theme.images/bullet.gif');
      list-style-type: square;
	}
  }
  ul {
    list-style: none;
  }
}
.portletStaticText,
.portlet {
  font-weight: normal;
  position: relative;
  /*margin-top: 1em;*/
  margin-bottom: 1em;
  box-shadow: none;
}
#portal-column-one .portletStaticText > *,
#portal-column-one .portlet > * {
  padding-right: 1em;
  padding-left: 1em;
}
#portal-column-one .portlet, #portal-column-one .portlet > header, #portal-column-two .portlet {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
#viewlet-below-content {
  clear: both;
}
#viewlet-below-content .portletStaticText > *,
#viewlet-below-content .portlet {
  padding: 0;
  p {
	padding: 0;
  }
}
.portletStaticText > *,
.portlet > * {
  margin: 0;
  padding: 0;
}
.portlet * {
  font-weight: normal;
}
.portlet ul {
  list-style-type: none;
  list-style-image: none;
  line-height: 1em;
  padding-left: 0;
}
.portlet li {
  display: list-item;
  line-height: 1.3em;
}
.portlet dt {
  display: none;
}
dl.portlet-linklist.simple li {
  padding: 0 .9em;
}

#viewlet-below-content .card-header {
  display: block;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  font-size: 115%;
  padding: 0;
  margin: 0;
}

.portlet .hiddenStructure {
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  display: block;
  height: 0.1em;
  overflow: hidden;
  width: 1px;
  position: absolute;
  top: 0.5em;
  z-index: -10 !important;
}
.portletError {
  background-color: #ffa500;
}
.portlet dt,
.portletAssignments .card-header {
  margin: 0 0 0.2em;
  line-height: normal;
  text-align: left;
}
.portletAssignments {
  font-size: 0.8em;
  line-height: 2em;

  select {
    display: block;
  }
}
.portletAssignments .managedPortletActions {
  float: right;
  margin-top: 0;
}
.portletAssignments .managedPortletActions a {
  margin: 0 0.25em;
  border: none;
}
.portlet dt a:link,
.portlet dt a:visited,
.portlet dt a:hover {
  display: block;
  border: none;
}

/* portlet-portal-title */

.portlet-portal-title {
  min-height: 9em;
  margin: 0 !important;
}
.portlet-portal-title dd .hidden-title {
  visibility: hidden;
  top: 0em;
  position: relative !important;
}
.portlet-portal-title dd > * {
  position: absolute;
  max-width: 85%;
  bottom: 0.25em;
  vertical-align: text-bottom;
}

.portlet-portal-title .portal-title-current {
  padding-bottom: .3em;
}
.portlet-portal-title .portal-title-current a {
  font-weight: normal;
  font-size: 110%;
}
@media screen and (max-width: 767.98px) {
  .portlet-portal-title .portal-title-current {margin-left: 1rem;}
}

/* navigation */
.portletNavigationTree {
  margin: 0 !important;
}

#sidebar {
  padding: 0;
}

/*zl_temp add !important*/
#portal-column-one {
  margin-top: -115.2px!important;
  padding: 0!important;

  .portletWrapper {
    padding: 0;
  }
  .portletNavigationTree {
    .card-body {
      padding: 0;

      > ul > li > a {
        &:hover {
          background: transparent;
        }
        &:hover::after {
          content: '';
        }
      }
      .navTreeCurrentNode > a {
        &::after {
          content: '';
        }
      }
    }

    .navTreeCurrentItem {
      font-weight: bold;
    }

    [class*='contenttype-']::before {
      content: '';
      display: none;
    }
  }

  ul.navTree li.navTreeItem a {
    padding-left: 0;
    padding-right: 1em;
    padding-top: 0.2em;
    padding-bottom: 0.3em;
    line-height: 1.2em;
  }

  ul.navTree {
    a {
      text-decoration: none;
    }
    &.navTreeLevel0 li a {
      padding-left: 5.26%; /* 1% * 100/19 */
    }
    .navTreeLevel1 li a {
      padding-left: 10.52%; /* 2% * 100/19 */
    }
    .navTreeLevel2 li a {
      padding-left: 15.78%; /* 3% * 100/19 */
    }
    .navTreeLevel3 li a {
      padding-left: 21.05%; /* 4% * 100/19 */
    }
    .navTreeLevel4 li a {
      padding-left: 26.31%; /* 5% * 100/19 */
    }
  }
  ul.navigationList_first {
    padding-left: 0;
    padding-right: 0;
  }
}

#portal-column-one
  .portletNavigationTree
  ul.navTree
  li.navTreeItem:last-child
  a {
  min-height: 1.3em;
}
#portal-column-two .portletNavigationTree ul.navTree li.navTreeItem a {
  padding: 0.2em 1em 0.2em;
}
.portlet.portletNavigationTree ul.navTree li.navTreeItem a {
  background-image: none !important;
  border-top-width: 0.1em;
  border-top-style: solid;
  border-color: white;
  display: block;
  line-height: 1.4em;
}
.portletNavigationTree ul.navTree li.navTreeItem a span {
  position: relative;
  bottom: -0.05em;
}
.portletNavigationTree dd.portletItem,
.portletNavigationTree dd.portletFooter {
  padding: 0em !important;
}

.navTree li {
  margin: 0em;
  padding: 0em;
}

.navTree img {
  display: none;
  float: left;
  padding-right: 0.25em;
}

li.navTreeItemInPath ul.navTreeLevel3 li {
  border-top: 1px solid #f0f0e8;
  word-wrap: break-word;
}
.navTree a.navTreeCurrentItem {
  background-color: white;
}

.portletNavigationTree
  ul.navTree.navTreeLevel0:first-child
  li.navTreeCurrentNode {
  /*    border-top: 1px solid #cccc99; */
}
.portlet.portletNavigationTree
  ul.navTree.navTreeLevel0:first-child
  li.navTreeItem:first-child
  a {
  border-top: 1px solid #cccc99;
}
.portlet.portletNavigationTree ul.navTree.navTreeLevel1 li.navTreeItem a {
  border-top: 1px solid white !important;
}

.portletNavigationTree ul.navTree li a span {
  display: block;
}
.portletNavigationTree ul.navTree li.navTreeItem:first-child a {
  border-top: none !important;
}

.navTreeItem-mobile .navTreeLevel1 li.navTreeItem a img,
.navTreeItem-mobile .navTreeLevel1 li.navTreeItem a span {
  margin-left: 5%;
}
.navTreeItem-mobile .navTreeLevel2 li.navTreeItem a img,
.navTreeItem-mobile .navTreeLevel2 li.navTreeItem a span {
  margin-left: 10%;
}
.navTreeItem-mobile .navTreeLevel3 li.navTreeItem a img,
.navTreeItem-mobile .navTreeLevel3 li.navTreeItem a span {
  margin-left: 15%;
}
.navTreeItem-mobile ul.navTreeLevel1 {
  background-color: #e0e0d1;
}
.navTreeItem-mobile ul.navTreeLevel2 {
  background-color: #f0f0e8;
}
.navTreeItem-mobile ul.navTreeLevel3 {
  background-color: white;
}
.contenttreeWidget .navTreeItem li {
  display: block;
  padding-left: 1em;
}
/* portletCalandar */
.portletCalendar {
  width: auto;
}
#portal-column-one .portletCalendar {
  margin: 0.06em 0;
  /*background-color: rgb(229,234,239);*/
  .card-body {
	background: transparent;
  }
  .card-header a {
    background: none;
	border-radius: 0;
  }
}
#portal-column-two .portletCalendar {
  padding: 0;
}
.portletCalendar .card-header {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-left: 1em;
  position: relative;
  font-weight: bold;
  font-size: 100%;
}
.portletCalendar .card-header,
.portletCalendar .portletFooter {
  display: block;
  margin: 0;
}
.portletCalendar > section.card-body {
  padding: 0 .9em !important;
}
.portletCalendar dd {
  overflow: hidden;
}
.portletCalendar .card-header span.portletTopRight {
  position: absolute;
  right: 1em;
  color: #036;
  font-weight: bold;
}
.portletCalendar .card-header span > * {
  display: inline-block !important;
}
#portal-column-one table.ploneCalendar {
  padding: 0 1em 0.3em 2em;
}
#portal-column-two table.ploneCalendar {
  padding: 0 1em 0.3em 1em;
}
table.ploneCalendar tr {
  line-height: 1.3em !important;
}
table.ploneCalendar th {
  border-width: 0.1em 0;
  border-style: solid;
  height: auto !important;
}
portlet.portletCalendar table tr
.ploneCalendar tr.weekdays th,
.ploneCalendar td {
  text-align: center;
  padding: 0 !important;
}
.ploneCalendar td > span {
  margin: 0 !important;
}
.ploneCalendar td.today span {
  border-radius: 0 !important;
  background: white !important;
}
.ploneCalendar .todaynoevent,
.ploneCalendar .todayevent,
.ploneCalendar .cal_has_events a {
  font-weight: bold !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}
.ploneCalendar .cal_has_events a::after {
  display: none;
}

#portal-column-two .portletWrapper .portletCalendar .card-header {
  font-size: 100%;
}

#portal-column-two .portletWrapper .portlet .card-header {
  margin-bottom: .5em;
  margin-left: .7rem;
}

/* portletLogin */
.portletLogin {
  .portletItem {
    a {
      padding-left: 1.1em;
    }
  }
  .field {
    margin-bottom: .2em;
  }
}
.portletLogin .card-header {
  display: none;
}
.portletLogin form#loginform {
  margin: 0 1em 1em 1em;
  .field {
    position: relative;
  }
}
form#loginform .field input {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 1.2em;
  width: 50%;
  padding: 9px 12px;
}
/* #loginform .formControls {
  margin: 0;
} */
#loginform .formControls input {
  position: absolute;
  right: 1em;
  margin-top: .5em;
}
.portletLogin .portletItem.even {
  width: 63%;
}
.portletLogin .portletItem.even a {
  font-size: 85%;
}

/* portletSearch */
.portletSearch .card-header {
  display: none;
}
.portletSearch .LSBox {
  height: 18px;
  position: relative;
}
.portletSearch input.searchField {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 22px;
  line-height: 18px;
  vertical-align: top;
  padding: .3rem .7rem;
  outline: none;
}
.portletSearch.huSearch input.searchField {
  position: relative;
  /*  top: -1px;
  left: -5px; */
}
.portletSearch input.inputLabel + input {
  position: absolute;
  right: 0;
  top: 0;
  height: 22px;
  width: 20px;
}
.portletSearch .portletFooter a {
  font-size: 85%;
}

/* relatedItemBox */
dt dl#relatedItemBox {
}
#relatedItemBox dt {
  display: block;
  margin-bottom: 0.3em;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  font-weight: normal;
  color: #036;
}
#relatedItemBox dd {
  margin: 0 0 0.1em;
  line-height: 1.3em;
  min-height: 16px;
  overflow: hidden;
}
#relatedItemBox dd span {
  padding-top: 3px;
}
#relatedItemBox dd img {
  padding-bottom: 1px;
}
#relatedItemBox dd a {
}
/* portletRss */
.portletItemDetails {
  display: block;
  font-size: 85%;
  line-height: 1.5em;
  padding-bottom: 0.5em;
  /*text-align: right;*/
  color: #666666;
}
.portletRss dd {
  line-height: 120%;
  margin-bottom: 0.25em;
}
.portlet.portletRss dd.portletFooter {
  display: none;
}
/* portletStaticText */

.portletStaticText {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  overflow: hidden;
  padding: .3rem .7rem;
}


/* portlet-separator */

#portal-column-one .portlet.portlet-separator,
#portal-column-two .portlet.portlet-separator {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
.portlet.portlet-separator dt, .portlet.portlet-separator dd {
  display: none;
}
#portal-column-one .portlet.portlet-separator dd,
#portal-column-two .portlet.portlet-separator dd {
  padding: 0;
  margin: 0;
}
#portal-column-one .portlet.portlet-separator dd hr,
#portal-column-two .portlet.portlet-separator dd hr {
  padding: 0;
  margin: 0 0.7rem;
}

#hu-feedfeeder-frame {
  margin-left: 3.2em;
}

#portal-column-two .portletWrapper .portlet dd {
  line-height: normal;
}
#portal-column-two .portletWrapper .portlet .card-header {
  display: block;
  font-size: 115%;
  border-bottom: 1px solid #b2c1d1;
}

#portal-column-two .portletWrapper .portlet-separator .card-header,
#portal-column-two .portletWrapper .portlet-directlink .card-header,
#portal-column-two .portletWrapper .portletSearch .card-header {
  display: none;
}

.portletWrapper .portlet dd.portletItem .portletItemDetails,
#portal-column-two .portletWrapper .portlet dd.portletItem .portletItemDetails,
#portal-column-two .portletWrapper .portlet li.portletItem .portletItemDetails {
  color: #666666;
  text-align: left;
  margin-left: 0;
  padding-top: 0;
}

.portletWrapper .portletNews {
	.card-header {
	  display: block;
	  border-bottom: 1px solid #b2c1d1;
	  /*background-color: #CCD6E0 !important;*/
	  a {
		font-size: 100%;
		color: #003366 !important;
	  }
	}

	.card-body ul {
		padding: 0 1em 0 0;
		/*list-style-type: square;*/
		list-style-position: outside;
		li {
			padding: .3em 0;
			a {
				display: inline;
			}
		}
	}
	.portletFooter {
		background: none;
		box-shadow: none;
		padding: 0 0 0 1em;
		a {
			font-size: 100%;
			color: #000;
		}
	}
}

.portletWrapper .portletEvents .card-header {
  display: block;
  font-size: 115%;
  border-bottom: 1px solid #b2c1d1;
}

.portletItemDetails span {
  display: block;
}

.portletWrapper .portletEvents .portletItemDetails span {
  display: inline;
}

.portletWrapper .portletEvents span.location {
  display: block;
}

.portletWrapper .portletEvents .portletItemDetails abbr,
.portletWrapper .portletEvents .portletItemDetails abbr .explain {
  border-bottom: none;
}

.portletWrapper .portletEvents .portletItemDetails span.timezone,
.portletWrapper .portletEvents .portletFooter a.previous-events {
  display: none;
}

.collage-item {
  display: block;
  margin-bottom: 1em;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 8%;
}
.collage-first-object {
  margin-left: 0 !important;
  padding-left: 0 !important;
}
.collage-last-object {
  margin-right: 0 !important;
  padding-right: 0 !important;
}
.standard-topic h2 {
  margin-left: -0.65em;
  margin-right: -0.65em;
  padding-left: 0.65em;
  padding-right: 0.65em;
  background: none no-repeat scroll 0 0 #ccd6e0;
  display: block;
}

.eventDetails {
  clear: none;
  float: left;
  width: 25%;
  padding-top: 2em !important;
  padding-right: 2em;
  margin: 0;
}

#content .eventDetails table {
  margin: 0;
  border: none;
}

.eventDetails table.listing th,
.eventDetails table.listing td {
  background-color: #ffffff;
  border: none;
  float: left;
  clear: both;
  text-align: left;
  padding: 0;
}

.eventDetails table.listing th {
  color: #003366;
  font-weight: normal;
  font-size: 130%;
  padding-bottom: 0.2em;
}

.eventDetails table.listing td {
  color: #666666;
  margin-bottom: 1em;
}

.eventDetails ul {
  margin-left: 0 !important;
}

#event_edit_container .eventDetails {
  float: left;
}

.vevent .timezone,
.eventDetails .timezone {
  display: none !important;
}

#content .eventDetails li.category {
  display: block;
}

.vevent .datedisplay,
.vevent .ical {
  display: block;
  margin-bottom: 0;
}

.vevent abbr.dtstart,
.vevent abbr.dtend {
  font-weight: normal;
  border-bottom: none;
}

.vevent div.cal_info.clearfix,
.vevent div.cal_info.clearfix p.description {
  display: table-cell;
}

.vevent div.cal_info.clearfix h2.tileHeadline {
  width: 99%;
}

article.tileItem h2.tileHeadline {
  width: 99%;
}


.eventText {
  float: left;
  word-wrap: break-word;
  padding-top: 2em;
  width: 70%;
}

#content .eventDetails tr.odd,
#content .eventDetails tr.odd td {
  background-color: #ffffff;
}

#content .eventDetails abbr,
#content .eventDetails .explain {
  cursor: text;
  border: none;
}

body.portaltype-event #content-core .kssattr-atfieldname-text {
  padding-top: 2em;
}

body.portaltype-event #content-core .kssattr-atfieldname-text,
body.portaltype-event #content-core a#parent-fieldname-eventUrl {
  padding-left: 25%;
}

.eventDetails table.listing tr.odd th {
  border: none;
}

.portletCalendar .card-header span.portletTopRight a#calendar-next {
  float: none;
}

#edit-bar,
#content ul.formTabs,
#content-core ul.formTabs {
  border-radius: 0 0 0 0;
  border: 0;
}

#content-views li.selected a,
#content-views li a:hover,
#content li.formTab a.selected,
#content li.formTab a:hover,
#content-core li.formTab a.selected,
#content-core li.formTab a:hover,
#contentActionMenus,
.managePortletsLink,
a.managePortletsFallback,
dl.actionMenu.activated dd {
  border-radius: 0 0 0 0;
}

#portal-personaltools-wrapper dl.actionMenu.activated dd {
  bottom: 1.5em;
}

#content-views a,
#content li.formTab a,
#content-core li.formTab a {
  line-height: 2em;
}

/* humbol.portlet.directlink */
.portlet-directlink dt {
  display: none;
}

.portlet-directlink dd {
  position: relative;
}

.portlet-directlink input.inputLabel {
  vertical-align: top;
  width: 100%;
  height: 22px;
  line-height: 18px;
  padding-left: 1em;
  padding-right: 2em;
  outline: none;
  box-sizing: border-box;
}

.portlet-directlink input.inputLabel + input {
  position: absolute;
  right: 0;
  top: 0;
  height: 22px;
  width: 20px;
}

@media screen and (max-width: 1199.98px) {
  .portletCalendar .card-header span.portletTopLeft {
    display: none;
  }
  .portletCalendar .card-header span.portletTopRight {
    position: static;
  }
  .portletCalendar .card-header span.portletTopRight a#calendar-next {
    float: none;
  }
}

/* portlet languageSelect */
.portletLanguageSelect ul li {
  margin-right: 0.2em;
}
/* .portletLanguageSelect .portletItem a {
  margin-right: 0.3em;
} */

.portletLanguageSelect .portletItem a.selected {
  font-weight: bold;
}

.portletLanguageSelect ul {
  list-style: none;
  padding: 0;
  display: inline;
  margin-right: 1em;
}

.portletLanguageSelect ul li {
  display: inline !important;
}

#portal-column-two
  .portletWrapper
  .portlet.portletLanguageSelect
  .card-header {
  border-bottom: none;
  margin-right: 1em;
}

.portletLanguageSelect .portletItem {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 1em;
}

/* portlet collection */

.portlet.portletCollection {
  > ul > li > a {
    padding: 0;
  }
  dd {
    margin-bottom: 0.5em;
  }
}

.bigsliderpage {
  #portal-column-one {
    .portlet-portal-title .portal-title-current {
      display: none;
    }
  }
}

#portlet-manager-humboldt\.theme\.belowcontentportletmanager {
    display: none !important;
}

.managedPortlet .card-header {
    font-size: 1.1em;
}

.blockedPortlet .card-header {
    border-top: 1px dashed #ddd;
	border-bottom: 1px dashed #ddd;
    color: #000;
}

.blockedPortlet .card-header a {
    color: #666;
}

.portletWrapper {
	td {
		padding: .25em 0;
	}
}

.portletBarrierefreiheit .portletItem {
  padding-top: 2px;
  padding-bottom: 3px;
  padding-left: 1em;
  margin-right: 0.2em;
}

.portletBarrierefreiheit {
  background-color: #8099b2;
  height: 22px;
}

#portal-column-two
  .portletWrapper
  .portlet.portletBarrierefreiheit
  .card-header {
  border-bottom: none;
  margin-right: 1em;
}

.card-body .portletItem {
  padding: 0 0.7rem 0 0.7rem!important;
  border-top: 0!important;
}

.card .card-header {
  background-color: transparent;
}

.card .card-footer {
  font-size: 11px;
  border-top: 0;
  background-color: transparent;
  display: block;
}

.card .card-footer span{
  padding-left: 1em;
}

.card.portletStaticText .card-body {
  margin-left: .7rem;
}

.portlet-calendar .card-body .event 
{ 
  text-align: center; 
}

.vevent div.cal_date {
  margin: 1em 1.5em .5em 0;
  float: right;
  box-shadow: 0 1px 3px rgba(0,0,0,.17);
  border-radius: 0;
  text-align: center;
  overflow: hidden;
  width: 100px;
}

.portletWrapper .portlet-linklist dt.portletHeader{
  margin-left: 0.7rem;
  display: block;
  font-size: 115%;
  border-bottom: 1px solid #b2c1d1;
}

